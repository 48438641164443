// src/env.mjs
import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    VERCEL_GIT_COMMIT_SHA: z.string().optional(),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    AWS_S3_CUSTOM_DOMAIN: z.string().optional(),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_BACKEND_URL: z.string(),
    NEXT_PUBLIC_FRONTEND_URL: z.string(),
    NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY: z.string(),
    NEXT_PUBLIC_INTERCOM_APP_ID: z.string().optional(),
    NEXT_PUBLIC_SENTRY_DSN: z.string(),
    NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE: z.coerce.number().default(0.01),
    NEXT_PUBLIC_SENTRY_ENV: z.string(),
    NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN: z.string(),
    NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: z.string(),
    NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: z.string(),
    NEXT_PUBLIC_DATADOG_RUM_ENV: z.string(),
    NEXT_PUBLIC_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE: z.coerce.number().default(20),
    NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE: z.coerce.number().default(100),
    NEXT_PUBLIC_FRONT_CHAT_ID: z.string().optional(),
  },
  runtimeEnv: {
    NEXT_PUBLIC_BACKEND_URL: process.env.NEXT_PUBLIC_BACKEND_URL,
    NEXT_PUBLIC_FRONTEND_URL: process.env.NEXT_PUBLIC_FRONTEND_URL,
    NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY: process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY,
    NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    NEXT_PUBLIC_SENTRY_ENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
    NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN,
    NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
    NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    NEXT_PUBLIC_DATADOG_RUM_ENV: process.env.NEXT_PUBLIC_DATADOG_RUM_ENV,
    NEXT_PUBLIC_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE: process.env.NEXT_PUBLIC_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE,
    NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE: process.env.NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE,
    NEXT_PUBLIC_FRONT_CHAT_ID: process.env.NEXT_PUBLIC_FRONT_CHAT_ID,
    NODE_ENV: process.env.NODE_ENV,
  },
});

